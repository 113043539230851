/* eslint-disable prettier/prettier */
import jwtDecode from 'jwt-decode';
import jwt from 'jsonwebtoken';
import axios from 'src/utils/axios';
import liveAxios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { RSLUrl } from 'src/config';
import { getThemeInfo, setCountryStateCityInfo, setGoogleMapInfo, setSiteInfo, setSocialNetworkInfo } from './settings';

// ----------------------------------------------------------------------

const JWT_SECRET = 'minimal-secret-key';
const JWT_EXPIRES_IN = '5 days';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {}
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const getCompanyId = () => {
  return localStorage.getItem('companyId');
};

export const getUserId = () => {
  return localStorage.getItem('userId');
};

export const getUserData = () => {
  return localStorage.getItem('userData');
};

// Start
const { webAPI_URL } = RSLUrl;

export function login({ email, password, loginType }) {
  return async (dispatch) => {
    try {
      const response = await liveAxios.post(`${webAPI_URL}/user/login`, {
        email,
        password,
        loginType
      });
      if (response.data.status === 1) {
        localStorage.setItem(
          'userData',
          JSON.stringify(response.data.userInfo)
        );
        localStorage.setItem('referralPoster', response.data.userInfo.referralPoster);
        
        const obj = {
          primaryColor: response.data.userInfo.primaryColorCode
            ? response.data.userInfo.primaryColorCode
            : '#00AB55',
          secondaryColor: response.data.userInfo.secondaryColorCode
            ? response.data.userInfo.secondaryColorCode
            : '#007B55'
        };

        


        dispatch(getThemeInfo(obj));
        dispatch(
          setSiteInfo(
            response?.data?.siteInfo
              ? response?.data?.siteInfo
              : 20
          )
        );

        dispatch(
          setGoogleMapInfo(
            response?.data?.googleMapInfo
              ? response?.data?.googleMapInfo
              : null
          )
        );

        dispatch(
          setCountryStateCityInfo(
            response?.data?.countryStateCityInfo
              ? response?.data?.countryStateCityInfo
              : null
          )
        );

        dispatch(
          setSocialNetworkInfo(
            response?.data?.socialNetworkInfo
              ? response?.data?.socialNetworkInfo
              : null
          )
        );

        const user = response.data;

        const accessToken = jwt.sign({ userId: user.userInfo.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN
        });
        setSession(accessToken);
        localStorage.setItem('userId', user.userInfo.id);
        localStorage.setItem('companyId', user.userInfo.companyId);
        localStorage.setItem(
          'menuAccess',
          JSON.stringify(user.userInfo.menuAccess)
        );
        dispatch(slice.actions.loginSuccess({ user }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      throw error;
    }
  };
}

// End

// ----------------------------------------------------------------------

export function register({ email, password, firstName, lastName }) {
  return async (dispatch) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null);
    localStorage.clear();
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get('/api/account/my-account');
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data.user
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
