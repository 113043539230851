/* eslint-disable prettier/prettier */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
// import Search from './Search';
import Account from './Account';
import PropTypes from 'prop-types';
// import Languages from './Languages';
import { Icon } from '@iconify/react';
import NotificationSound from './NotificationSound';
import Notifications from './Notifications';
import NotificationsB2B from './NotificationsB2B';
import NotificationDojoin from './NotificationDojoin';
import Settings from 'src/layouts/Common/Settings';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {
  Box,
  AppBar,
  Hidden,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  dashboardNotificationCount
} from 'src/redux/slices/notifications';
import { getCompanyId } from 'src/redux/slices/authJwt';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backdropFilter: 'blur(8px)',
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('xl')]: {
      paddingLeft: DRAWER_WIDTH
    }
  },
  toolbar: {
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 5)
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP
    }
  }
}));

// ----------------------------------------------------------------------

TopBar.propTypes = {
  onOpenNav: PropTypes.func,
  className: PropTypes.string
};

function TopBar({ onOpenNav, className }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companyIdFromLocalStorage = Number(getCompanyId());
  const userData = JSON.parse(localStorage.getItem('userData'));

  const {
    dashboardNotificationCountData
  } = useSelector((state) => state.notifications);

  const [loader, setLoader] = useState(false);

  const [totalB2bReadStatusOne, setTotalB2bReadStatusOne] = useState(0);
  const [totalB2cReadStatusOne, setTotalB2cReadStatusOne] = useState(0);
  const [totalDojoinReadStatusOne, setTotalDojoinReadStatusOne] = useState(0);
  const [totalB2bUnread, setTotalB2bUnread] = useState(0);
  const [totalB2cUnread, setTotalB2cUnread] = useState(0);
  const [totalDojoinUnread, setTotalDojoinUnread] = useState(0);
  const [caseB2b, setCaseB2b] = useState('');
  const [caseB2c, setCaseB2c] = useState('');
  const [doJoin, setCaseDoJoin] = useState('');

  const [playSound, setPlaySound] = useState(false);

  const [playSoundB2b, setPlaySoundB2b] = useState(false);
  const [playSoundB2c, setPlaySoundB2c] = useState(false);
  const [playSoundDojoin, setPlaySoundDojoin] = useState(false);

  // ⚠️ Need to unComment ⚠️

  useEffect(() => {
    // console.log('loader:', loader);
    const userData = JSON.parse(localStorage.getItem('userData'));
    setLoader(true);
    setPlaySound(true);
    setPlaySoundB2b(true);
    setPlaySoundB2c(true);
    setPlaySoundDojoin(true);
    const intervalId = setInterval(() => {
      if (userData.userType === 'A') {
        dispatch(dashboardNotificationCount());
      }
      // console.log('Notification Call:');
    }, 10000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (dashboardNotificationCountData) {
      //  console.log('dashboardNotificationCountData:', dashboardNotificationCountData);

      // console.log('myData:', dashboardNotificationData.responseData);

      // B2b
      const b2bSection =
        dashboardNotificationCountData?.responseData?.responseData?.b2b || {};
  

      setTotalB2bReadStatusOne(b2bSection.count);
      
      setTotalB2bUnread(b2bSection.count);

      setCaseB2b(b2bSection.case);

      // B2c
      const b2cSection =
        dashboardNotificationCountData?.responseData?.responseData?.b2c || {};
 
      setTotalB2cReadStatusOne(b2cSection.count);

      setCaseB2c(b2cSection.case);

      setTotalB2cUnread(b2cSection.count);
      const dojoinSection =
        dashboardNotificationCountData?.responseData?.responseData?.doJoin ||
        {};
     
      setTotalDojoinReadStatusOne(dojoinSection.count);
      setTotalDojoinUnread(dojoinSection.count);
      setCaseDoJoin(dojoinSection.case);
    }
  }, [dashboardNotificationCountData]);

  useEffect(() => {
    // console.log('console 1.1:');
    if (loader) {
      // console.log('loader:', loader);
      if (dashboardNotificationCountData) {
        // console.log('myData:', dashboardNotificationData.responseData);

        // B2b
        const b2bSection =
          dashboardNotificationCountData?.responseData?.responseData?.b2b || {};

        // B2b

       
        setTotalB2bUnread(b2bSection.count);
        // B2c
        const b2cSection =
          dashboardNotificationCountData?.responseData.responseData?.b2c || {};

  
        setTotalB2cUnread(b2cSection.count);

        // Dojoin
        const doJoinSection =
          dashboardNotificationCountData?.responseData?.responseData?.doJoin ||
          {};

        setTotalDojoinUnread(doJoinSection.count);

        setLoader(false);
      }
    }
  }, [dashboardNotificationCountData]);

  //  console.log('caseB2c:', caseB2c);

  // For B2b Sound

  useEffect(() => {
    // console.log('comes 1.1', totalB2bReadStatusOne, totalB2bUnread);
    // console.log('calculatedTotalB2cReadStatusOne: 3');
    if (totalB2bUnread > 0) {
      // console.log('Comes 1.2');
      setTotalB2bUnread(totalB2bReadStatusOne);
      setPlaySound(true);
      setPlaySoundB2b(true);
      // console.log('calculatedTotalB2cReadStatusOne: 4');
    }
  }, [totalB2bReadStatusOne]);

  // // For B2c Sound

  useEffect(() => {
    // console.log('totalB2cReadStatusOne:',totalB2cReadStatusOne);
    // console.log('totalB2cUnread:',totalB2cUnread);

    if (totalB2cUnread > 0) {
      setTotalB2cUnread(totalB2cReadStatusOne);
      setPlaySound(true);
      setPlaySoundB2c(true);
    }
  }, [totalB2cReadStatusOne]);

  // // For Dojoin sound
  useEffect(() => {
    if (companyIdFromLocalStorage === 0) {
      if (totalDojoinUnread > 0) {
        setTotalDojoinUnread(totalDojoinReadStatusOne);
        setPlaySound(true);
        setPlaySoundDojoin(true);
      }
    }
  }, [totalB2cReadStatusOne]);

  // console.log('totalB2bUnread', totalB2bUnread);

  // console.log('totalUnread: 2', totalB2bUnread);
  // console.log('totalUnread: 2.1', totalB2bReadStatusOne);
  // console.log('totalUnread: 3', playSoundB2b, playSoundB2c, playSound);

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        <Hidden xlUp>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary'
            }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </Hidden>

        {/* <Search /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: {
                xs: 0.5,
                sm: 2,
                lg: 3
              }
            }
          }}
        >
          {/* Check committ */}
          {/* <Languages /> */}

          {/* Jackson Start */}

          {userData.userType === 'A' && (
            <>
              {/* <Notifications  /> */}
              <NotificationSound
                playSound={playSound}
                setPlaySound={setPlaySound}
              />
              {/* B2B */}
              <NotificationsB2B
                caseB2b={caseB2b}
                playSound={playSoundB2b}
                setPlaySoundB2b={setPlaySoundB2b}
                count={totalB2bUnread}
              />

              {/*B2C */}
              <Notifications
                caseB2c={caseB2c}
                playSound={playSoundB2c}
                setPlaySoundB2c={setPlaySoundB2c}
                count={totalB2cUnread}
              />
            </>
          )}

          {/* DoJoin */}

          {companyIdFromLocalStorage === 0 && userData.userType === 'A' && (
            <NotificationDojoin
              doJoin={doJoin}
              playSound={playSoundDojoin}
              setPlaySoundDojoin={setPlaySoundDojoin}
              count={totalDojoinUnread}
            />
          )}

          {/* Jackson End */}
          <Settings />
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
