/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { PATH_APP, PATH_APP_CORP, PATH_APP_WEBBOOK } from 'src/routes/paths';
import { Redirect, useHistory } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { RSLUrl } from 'src/config';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

function GuestProtect({ children }) {
  const history = useHistory();
  
 


  const { isLoading, isAuthenticated, user } = useAuth();
  const accessToken = localStorage.getItem('accessToken');
 
  const queryParams = new URLSearchParams(window.location.search);
  const autoLoginEmail = queryParams.get('email');
  const autoLoginPassword = queryParams.get('password');
  // console.log(accessToken, 'accessToken');
  if (isLoading) {
    return <LoadingScreen />;
  }

  // console.log('PathName:', window.location.pathname);

  // Path Condition start

  var url = window.location.pathname;
  var parts = url.split('/');

  // Access the third part directly (index 2)
  var desiredPart = parts[2];

  // console.log('Login-', desiredPart);

  // Path Condition end
  const { commonAdmin_URL } = RSLUrl;

  if (
    desiredPart === 'login' &&
    !accessToken &&
    !autoLoginPassword &&
    !autoLoginEmail
  ) {
    const removeActiveTabParam = 'removeactivetab';
    localStorage.setItem('isLoggedIn', '1');
    const logoutParam = 'logout'; // Add your logout parameter here
    const logoutUrl = `${commonAdmin_URL}?${removeActiveTabParam}&${logoutParam}`;
    // const logoutUrl = `http://localhost:3033/login?${removeActiveTabParam}&${logoutParam}`

    window.location.href = logoutUrl;
  } else if (desiredPart === 'corporateLogin') {
    localStorage.removeItem('isLoggedIn');
    localStorage.setItem('loginType', 'CP');
  } else if (desiredPart === 'webBookerLogin') {
    localStorage.removeItem('isLoggedIn');
    localStorage.setItem('loginType', 'WB');
  } else if (desiredPart === 'manualLogin') {
    localStorage.removeItem('isLoggedIn');
    localStorage.setItem('loginType', 'A');
  }

  // console.log('userDatas:', user);

  // if (isAuthenticated) {
  //   if (user?.userInfo?.userType === 'CP') {
  //     // console.log("Check:CP");
  //     return <Redirect to={PATH_APP_CORP.root} />;
  //   } else if (user?.userInfo?.userType === 'WB') {
  //     return <Redirect to={PATH_APP_WEBBOOK.root} />;
  //   } else {
  //     // console.log("Check:A");
  //     return <Redirect to={PATH_APP.root} />;
  //   }
  // }

  if (isAuthenticated) {
    if (user?.userInfo?.userType === 'CP') {
      // console.log("Check:CP");
      return <Redirect to={PATH_APP_CORP.root} />;
    } else if (user?.userInfo?.userType === 'WB') {
      return <Redirect to={PATH_APP_WEBBOOK.root} />;
    } else {
      // console.log("Check:A");
      return <Redirect to={PATH_APP.root} />;
    }
  }

  return <>{children}</>;
}

export default GuestProtect;
