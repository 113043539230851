/* eslint-disable prettier/prettier */
import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP, PATH_PAGE } from 'src/routes/paths';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;
// const path1 = (name) => `/static/icons/navbar/png/${name}.png`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  marketing: <MIcon src={path('Blogs')} />,
  toll: <MIcon src={path('toll')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
  locationLog: <MIcon src={path('Artboard 2')} />,
  locationGroup: <MIcon src={path('Artboard 4')} />,
  order: <MIcon src={path('Artboard 6')} />,
  settings: <MIcon src={path('Artboard 9')} />,
  report: <MIcon src={path('Artboard 3')} />,
  rider: <MIcon src={path('Artboard 5')} />,
  superVisor: <MIcon src={path('Artboard 10')} />,
  driverManagement: <MIcon src={path('name-id-icon')} />,
  carDrivers: <MIcon src={path('driver-icon')} />,
  taxiIcon: <MIcon src={path('taxi-icon')} />,
  liveTracking: <MIcon src={path('liveTracking')} />,
  corporate: <MIcon src={path('corporate')} />,
  setting: <MIcon src={path('setting-icon')} />,
  location: <MIcon src={path('location')} />,
  discount: <MIcon src={path('discount-icon')} />,
  customer: <MIcon src={path('customer')} />,
  companies: <MIcon src={path('companies')} />,
  cars: <MIcon src={path('cars-icon')} />,
  permisssion: <MIcon src={path('user')} />,
};


const navConfig = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Dashboard',
        href: PATH_APP.report.engine,
        icon: ICONS.dashboard
      },
      {
        title: 'NDS',
        href: PATH_APP.report.report_new,
        icon: ICONS.report
      },
      {
        title: 'Dispatch Map',
        href: PATH_APP.report.taxiDispatch,
        icon: ICONS.liveTracking
      },
      // {
      //   title: 'Live Tracking',
      //   href: PATH_PAGE.comingSoon,
      //   icon: ICONS.liveTracking
      // },
      {
        title: 'Orders',
        href: PATH_APP.report.newBooking,
        icon: ICONS.order
      },
      {
        title: 'Manage Permission',
        href: PATH_APP.report.companyList,
        icon: ICONS.order
      },
      // Need to comment ⚠️
      {
        title: 'Manage Handover',
        href: PATH_APP.report.manageHandover,
        icon: ICONS.order
      },
      {
        title: 'Custom Zone',
        href: PATH_APP.report.manageCustomZone,
        icon: ICONS.location
      },
      {
        title: 'LifeStyle Offer Zone',
        href: PATH_APP.report.manageOfferZone,
        icon: ICONS.location
      },
    ]
  },
  {
    items: [
      {
        title: 'Divisions',
        icon: ICONS.charts,
        href: PATH_APP.report.manageDivision,
      
      }
    ]
  },
  {
    items: [
      {
        title: 'Departments',
        icon: ICONS.locationGroup,
        href: PATH_APP.report.manageDepartment,
      
      }
    ]
  },
  {
    items: [
      {
        title: 'Drivers',
        icon: ICONS.carDrivers,
        items: [
          {
            title: 'Manage Drivers',
            href: PATH_APP.report.manageDriver
          },
          {
            title: 'Manage New Drivers',
            href: PATH_APP.report.manageNewDrivers
          },
          {
            title: 'Export Driver List',
            href: PATH_APP.report.exportDriverList
          },
          // Need to comment ⚠️
          {
            title: 'Manage lost/found logs',
            href: PATH_APP.report.lostFoundList
          },
          // {
          //   title: 'New Driver Request',
          //   href: PATH_PAGE.comingSoon,
          //   icon: ICONS.page
          // },
          // {
          //   title: 'Driver Subscription',
          //   href: PATH_PAGE.comingSoon,
          //   icon: ICONS.page
          // },
          {
            title: 'Driver Document Request',
            href: PATH_APP.report.driverDocument,
            icon: ICONS.page
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: 'Cars',
        icon: ICONS.cars,
        items: [
          // {
          //   title: 'Manage Fare',
          //   href: PATH_APP.report.manageFare,
          //   icon: ICONS.page
          // },
          {
            title: 'Manage Models',
            href: PATH_APP.report.manageModel,
            icon: ICONS.page
          },
          {
            title: 'Manage Cars',
            href: PATH_APP.report.manageCars,
            icon: ICONS.page
          },
          // uncommented for sheet 
          // {
          //   title: 'Car Models',
          //   href: PATH_PAGE.comingSoon,
          //   icon: ICONS.page
          // },
          {
            title: 'Assign Car',
            href: PATH_APP.report.assignCar,
            icon: ICONS.page
          },
          {
            title: 'Manage Assigned Cars',
            href: PATH_APP.report.assignCars,
            icon: ICONS.page
          },
          {
            title: 'Taxi Subscription List',
            href: PATH_APP.report.taxiList,
            icon: ICONS.page
          },
          {
            title: 'Car Manufacturer',
            href: PATH_APP.report.manageManufacturer,
            icon: ICONS.page
          },
          {
            title: 'Car Make',
            href: PATH_APP.report.manageCarMake,
            icon: ICONS.page
          },
        ]
      }
    ]
  },
  {
    items: [
      {
        title: 'Companies',
        icon: ICONS.companies,
        href: PATH_APP.report.companies,
        items: [
          {
            title: 'Manage Company',
            href: PATH_APP.report.companies,
            icon: ICONS.page
          },
          {
            title: 'Manage Promocode',
            href: PATH_APP.report.managePromocode,
            icon: ICONS.page
          },
        
        ]
      }
    ]
  },
  // {
  //   items: [
  //     {
  //       title: 'Customers',
  //       icon: ICONS.customer,
  //       items: [
  //         {
  //           title: 'Passenger Wallet Logs',
  //           href: PATH_PAGE.comingSoon,
  //           icon: ICONS.page
  //         },
  //         {
  //           title: 'Passenger Request',
  //           href: PATH_PAGE.comingSoon,
  //           icon: ICONS.page
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    items: [
      {
        title: 'Corporates',
        icon: ICONS.corporate,
        href: PATH_APP.report.zone,
        items: [
          {
            title: 'Manage Corporate',
            href: PATH_APP.report.corporates,
            icon: ICONS.page
          },
          {
            title: 'Corporate Packages',
            href: PATH_APP.report.corporatePackage,
            icon: ICONS.page
          },
          // {
          //   title: 'Manage Location',
          //   href: PATH_APP.report.companyLocations,
          //   icon: ICONS.page
          // },
          {
            title: 'Manage zone',
            href: PATH_APP.report.manageZone,
            icon: ICONS.page
          },
          {
            // title: 'Zone',
            title: 'Zone Map View',
            href: PATH_APP.report.zone,
            icon: ICONS.elements
          }
        ]
      }
    ]
  },

 
  {
    items: [
      {
        title: 'Passengers',
        icon: ICONS.user,
        href: PATH_APP.report.passengers,
        items: [
          {
            title: 'Manage Passengers',
            href: PATH_APP.report.passengers,
            icon: ICONS.page
          },
          {
            title: 'Passengers Package',
            href: PATH_APP.report.passengerPackage,
            icon: ICONS.page
          },
        ]
      }
    ]
  },
  {
    items: [
      {
        title: 'Locations',
        icon: ICONS.location,
        href: PATH_APP.report.zone,
        items: [
          //TODO Working progress start customize Fare

          {
            title: 'Manage Location',
            href: PATH_APP.report.companyLocations,
            icon: ICONS.page
          },
          {
            title: 'Manage Queue Location',
            href: PATH_APP.report.manageLocationQueue,
            icon: ICONS.page
          },

          //Working progress end

          // Location Group
          {
            title: 'Location Group',
            href: PATH_APP.report.locationGroup,
            icon: ICONS.map
          },

          // {
          //   title: 'test',
          //   href: PATH_APP.report.test,
          //   icon: ICONS.map
          // },
          {
            title: 'Location Log',
            href: PATH_APP.report.timeZone,
            icon: ICONS.locationLog
          },

          {
            title: 'Location Queue Driver Log',
            href: PATH_APP.report.locationQueueLogs,
            icon: ICONS.locationLog
          }
        ]
      }
    ]
  },

  // Tolls

  {
    items: [
      {
        title: 'Tolls',
        icon: ICONS.toll,
        href: PATH_APP.report.manageTolls,
        items: [
          {
            title: 'Manage Tolls',
            href: PATH_APP.report.manageTolls,
            icon: ICONS.toll
          },
        ]
      }
    ]
  },

  {
    items: [
      {
        title: 'Supervisor',
        icon: ICONS.superVisor,
        href: PATH_APP.report.supervisor,
        items: [
          {
            title: 'Supervisor',
            href: PATH_APP.report.supervisor,
            icon: ICONS.superVisor
          },
          {
            title: 'Supervisor Trip Reports',
            href: PATH_APP.report.supervisorTripReports
          },
          {
            title: 'Supervisor Logs',
            href: PATH_APP.report.supervisorLogs
          }
        ]
      }
    ]
  },
  // {
  //   items: [
  //     {
  //       title: 'Fine',
  //       icon: ICONS.superVisor,
  //       href: PATH_APP.report.supervisor,
  //       items: [
  //         {
  //           title: 'Manage Fine',
  //           href: PATH_APP.report.manageFine,
  //           icon: ICONS.elements
  //         },
  //         {
  //           title: 'Manage Fine Category',
  //           href: PATH_APP.report.manageFineCategory,
  //           icon: ICONS.elements
  //         },

  //       ]
  //     }
  //   ]
  // },

  {
    items: [
      {
        title: 'Discount',
        icon: ICONS.discount,
        // href: PATH_APP.report.driver,
        items: [
          {
            title: 'Mobile Promotion',
            href: PATH_PAGE.comingSoon,
            icon: ICONS.page
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: 'Marketing',
        icon: ICONS.marketing,
        href: PATH_APP.report.zone,
        items: [
          {
            title: 'Lifestyle Location',
            href: PATH_APP.report.lifestyleLocations,
            icon: ICONS.location
          },
          {
            title: 'Manage Referral Settings',
            href: PATH_APP.report.manageReferralSettings
          },
         
        ]
      }
    ]
  },

  // {
  //   items: [
  //     {
  //       title: 'Locations',
  //       icon: ICONS.location,
  //       // href: PATH_APP.report.driver,
  //       items: [
  //         {
  //           title: 'Location Queue',
  //           href: PATH_PAGE.comingSoon,
  //           icon: ICONS.elements
  //         },
  //         {
  //           title: 'Manage Location',
  //           href: PATH_APP.report.manageLocations,
  //           icon: ICONS.elements
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   // subheader: 'DashboardUser',
  //   items: [
  //     {
  //       title: 'Supervisor',
  //       href: PATH_APP.report.supervisor,
  //       icon: ICONS.superVisor
  //     },

  //     {
  //       title: 'Location Log',
  //       href: PATH_APP.report.timeZone,
  //       icon: ICONS.locationLog
  //     },
  //   ]
  // },
  {
    // subheader: 'Dashboard',
    items: [
      {
        title: 'Reports',
        // icon: ICONS.elements,
        icon: ICONS.report,
        href: PATH_APP.report.report,
        items: [
          {
            title: 'Driver Shift Hours',
            href: PATH_PAGE.comingSoon
          },
          {
            title: 'Settlement',
            href: PATH_APP.report.settle
          },
          {
            title: 'Driver Status',
            href: PATH_APP.report.driverStatus
          },
          {
            title: 'Exit Record',
            href: PATH_APP.report.exitRecord
          },
          {
            title: 'Geo Report',
            href: PATH_APP.report.geoReport
          },
          {
            title: 'Location Queue Driver Logs',
            href: PATH_APP.report.LocationQueueDriverLogs
          },
          {
            title: 'All Other Reports',
            href: PATH_PAGE.comingSoon
          },
          {
            title: 'Driver Rejection Report',
            href: PATH_APP.report.driverRejectionReport
          },
        ]
      }
    ]
  },
  {
    items: [
      {
        title: 'Trip Reports',
        icon: ICONS.report,
        href: PATH_APP.report.allTransactions,
        items: [
          {
            title: 'All Transactions',
            href: PATH_APP.report.allTransactions
          },
          {
            title: 'Completed Transactions',
            href: PATH_APP.report.CompletedTransactions
          },
          {
            title: 'Cancelled Transactions',
            href: PATH_APP.report.cancelledTransactions
          },
          {
            title: 'Account Reports',
            href: PATH_APP.report.accountReports
          },
          // uncommented Start
          // {
          //   title: 'Rejected Trips',
          //   href: PATH_APP.report.rejectedTrips
          // },
          // {
          //   title: 'Pending Payment Details',
          //   href: PATH_APP.report.pendingPaymentDetails
          // },
          // {
          //   title: 'Cancel Trip Report',
          //   href: PATH_APP.report.cancelTripReport
          // },
          // {
          //   title: 'Driver Shift Reports',
          //   href: PATH_APP.report.driverShiftHistory
          // },
          // uncommented end
          {
            title: 'Supervisor Trip Reports',
            href: PATH_APP.report.supervisorTripReports
          },
          // uncommented Start
          // {
          //   title: 'Supervisor Logs',
          //   href: PATH_APP.report.supervisorLogs
          // },
          // uncommented end
          {
            title: 'Activity Logs',
            href: PATH_APP.report.activityLogs
          },
          {
            title: 'Historical Data',
            href: PATH_APP.report.historicalData,
          },
          // uncommented Start
          // {
          //   title: 'Zone Entry Logs',
          //   href: PATH_APP.report.zoneEntryLogs
          // }
          // uncommented end
        ]
      }
    ]
  },
  {
    // subheader: 'Dashboard',
    items: [
      {
        title: 'Settings',
        icon: ICONS.setting,
        href: PATH_APP.report.report,
        items: [
          {
            title: 'Site Settings',
            href: PATH_APP.report.manageSiteSettings
          },
          {
            title: 'SMS Templates',
            href: PATH_APP.report.manageSmsTemplates
          },
          {
            title: 'Admin SMTP Settings',
            href: PATH_APP.report.editAdminSMTPSetting
          },
          {
            title: 'Company SMTP Settings',
            href: PATH_APP.report.manageSmtpMailSetting
          },
          {
            title: 'Admin SMS Settings',
            href: PATH_APP.report.editSMSSMTPSetting
          },
          {
            title: 'Company SMS Settings',
            href: PATH_APP.report.manageSMSSetting
          },
          {
            title: 'Manage Country',
            href: PATH_APP.report.manageCountry
          },
          {
            title: 'Manage State',
            href: PATH_APP.report.manageState
          },
          {
            title: 'Manage City',
            href: PATH_APP.report.manageCity
          },
          {
            title: 'Manage Social Network',
            href: PATH_APP.report.addEditSocialNetwork
          },
          {
            title: 'Manage Google Settings',
            href: PATH_APP.report.manageGoogleSettings
          },
          // {
          //   title: 'Custome Zone',
          //   href: PATH_PAGE.comingSoon
          // },
          // {
          //   title: 'Permission',
          //   href: PATH_PAGE.comingSoon
          // },
          // {
          //   title: 'Tolls',
          //   href: PATH_PAGE.comingSoon
          // },
          // {
          //   title: 'All Other Settings',
          //   href: PATH_PAGE.comingSoon
          // }
        ]
      }
    ]
  },
  {
    items: [
      // {
      //   title: 'Location Group',
      //   href: PATH_APP.report.locationGroup,
      //   icon: ICONS.map
      // },
      {
        title: 'Rider',
        href: PATH_APP.report.pushNotification,
        icon: ICONS.rider,
      }
      // {
      //   title: 'Maps',
      //   href: PATH_APP.report.gMaps,
      //   icon: ICONS.rider
      // }
    ]
  }

  // {
  //   items: [
  //     {
  //       title: 'Car & Drivers',
  //       icon: ICONS.taxiIcon,
  //       href: PATH_APP.report.manageCars,
  //       items: [
  //         {
  //           title: 'Manage Cars',
  //           href: PATH_APP.report.manageCars
  //         },
  //         {
  //           title: 'Driver Management',
  //           href: PATH_APP.report.manageDriver,
  //           icon: ICONS.driverManagement,
  //           items: [
  //             {
  //               title: 'Manage Drivers',
  //               href: PATH_APP.report.manageDriver
  //             },
  //             {
  //               title: 'Manage New Drivers',
  //               href: PATH_APP.report.manageNewDrivers
  //             },
  //             {
  //               title: 'Export Driver List',
  //               href: PATH_APP.report.exportDriverList
  //             },
  //             {
  //               title: 'Manage lost/found logs',
  //               href: PATH_APP.report.lostFoundList
  //             }
  //           ]
  //         },
  //         {
  //           title: 'Manage Assigned Cars',
  //           href: PATH_APP.report.assignCars
  //         }
  //       ]
  //     }
  //   ]
  // }
];

export default navConfig;

