/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RSLUrl } from 'src/config';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  themeMode: 'light',
  themeDirection: 'ltr',
  paginationData: '',
};


const { webAPI_URL  } = RSLUrl;


const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    switchMode(state, action) {
      state.themeMode = action.payload;
    },
    switchDirection(state, action) {
      state.themeDirection = action.payload;
    },
    getThemeInfoSuccess(state, action) {
      state.isLoading = false;
      state.customTheme = action.payload;
    },
    getCompanyInfoSuccess(state, action) {
      state.isLoading = false;
      state.companyInfoRes = action.payload;
    },
    getCompanyLoaderSuccess(state, action) {
      state.isLoading = false;
      state.companyLoaderRes = action.payload;
    },
    setSiteInfoSuccess(state, action) {
      state.isLoading = false;
      state.siteInfoData = action.payload;
    },
    setGoogleMapInfoSuccess(state, action) {
      state.isLoading = false;
      state.googleMapInfoData = action.payload;
    },
    setSocialNetworkInfoSuccess(state, action) {
      state.isLoading = false;
      state.socialNetworkInfoData = action.payload;
    },
    setCountryStateCityInfoSuccess(state, action) {
      state.isLoading = false;
      state.countryStateCityInfoData = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { switchMode, switchDirection } = slice.actions;

export function getThemeInfo(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {

      if (obj !== undefined) {
        dispatch(slice.actions.getThemeInfoSuccess(obj));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getCompanyInfo(obj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axios.post(
        // `http://15.184.158.127:3004/user/getCompanyInfo`,
      
        `${webAPI_URL}/user/getCompanyInfo`,
        obj
      );
      if (response.data !== undefined) {
        dispatch(slice.actions.getCompanyInfoSuccess(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompanyLoader(value) {
  return async (dispatch) => {
    try {
        dispatch(slice.actions.getCompanyLoaderSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSiteInfo(value) {
  return async (dispatch) => {
    try {
        dispatch(slice.actions.setSiteInfoSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setGoogleMapInfo(value) {
  return async (dispatch) => {
    try {
        dispatch(slice.actions.setGoogleMapInfoSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSocialNetworkInfo(value) {
  return async (dispatch) => {
    try {
        dispatch(slice.actions.setSocialNetworkInfoSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCountryStateCityInfo(value) {
  return async (dispatch) => {
    try {
        dispatch(slice.actions.setCountryStateCityInfoSuccess(value));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
