/* eslint-disable prettier/prettier */
// ----------------------------------------------------------------------

const disableMenus = localStorage.getItem('disableMenus');

//  console.log("disableMenus", typeof(disableMenus));

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app',
  corporate: '/corporate',
  docs: '/docs',
  user: '/user',
  webBooker: '/web-booker'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    logindirect: path(ROOTS.auth, '/logindirect/:id'),
    login: path(ROOTS.auth, '/login'),
    login2: path(ROOTS.auth, '/manualLogin'),
    corporateLogin: path(ROOTS.auth, '/corporateLogin'),
    webBookerLogin: path(ROOTS.auth, '/webBookerLogin'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    resetCorporatePassword: path(ROOTS.auth, '/reset-corporate-password'),
    verify: path(ROOTS.auth, '/verify')
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment'
};

export const PATH_HOME = {
  components: '/components',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  dashboard: ROOTS.app
};

export const PATH_USER = {
  root: ROOTS.user,
  report: {
    root: path(ROOTS.user, '/manage'),
    settle: path(ROOTS.user, '/engine-report/settle/:id'),
    driverStatus: path(ROOTS.user, '/engine-report/driverStatus'),
  }
};

export const PATH_APP_CORP = {
  root: ROOTS.corporate,
  report: {
    root: path(ROOTS.corporate, '/corprateBooking'),
    corprateBooking: path(ROOTS.corporate, '/corprateBooking'),
    corporateProfile: path(ROOTS.corporate, '/profile'),
  }
}

export const PATH_APP_WEBBOOK = {
  root: ROOTS.webBooker,
  report: {
    root: path(ROOTS.webBooker, '/webBooker'),
    webBooking: path(ROOTS.webBooker, '/webBooker'),
    corporateProfile: path(ROOTS.webBooker, '/profile'),
  }
}

export const PATH_APP = {
  root: ROOTS.app,
  report: {
    //  root: path(ROOTS.app, '/dashboard'),
    root: parseInt(disableMenus) === 0 ? path(ROOTS.app, '/taxiDispatch') : path(ROOTS.app, '/dashboard'),
    // root: path(ROOTS.app, '/newBooking'),
    // root: path(ROOTS.app, '/taxiDispatch'),
    settle: path(ROOTS.app, '/engine-report/settle/:id'),
    driverStatus: path(ROOTS.app, '/engine-report/driverStatus'),
    supervisor: path(ROOTS.app, '/supervisor'),
    supervisorInfo: path(ROOTS.app, '/supervisor/supervisorInfo/:id'),
    addEditSupervisor: path(ROOTS.app, '/supervisor/addEditSupervisor/:id'),
    supervisorDetails: path(ROOTS.app, '/supervisor/supervisorDetails/:id'),
    exitRecord: path(ROOTS.app, '/engine-report/exitrecord'),
    geoReport: path(ROOTS.app, '/engine-report/geoReport'),
    geoReportDetails: path(ROOTS.app, '/engine-report/geoReport/:id'),
    gMaps: path(ROOTS.app, '/engine-report/gMap'),
    locationGroup: path(ROOTS.app, '/engine-report/locationGroup'),
    test: path(ROOTS.app, '/engine-report/test'),
    locationGroupAdd: path(ROOTS.app, '/engine-report/locationGroup/add'),
    locationGroupEdit: path(ROOTS.app, '/engine-report/locationGroup/edit'),
    zone: path(ROOTS.app, '/zone'),
    pushNotification: path(
      ROOTS.app,
      '/engine-report/PassengerPushNotification'
    ),
    pushNotificationDetails: path(
      ROOTS.app,
      '/engine-report/PassengerPushNotification/:id'
    ),
    timeZone: path(ROOTS.app, '/timeZone'),
    // manageDrivermain: path(ROOTS.app, '/manage/manageDriver'),
    profile: path(ROOTS.app, '/manage/profile'),
    companies: path(ROOTS.app, '/manage/manageCompany'),
    companiesAdd: path(ROOTS.app, '/manage/manageCompany/addCompany'),
    corporateAdd: path(ROOTS.app, '/manage/manageCorporate/addCorporate'),
    corporateEdit: path(ROOTS.app, '/manage/manageCorporate/editCorporate/:id'),
    zoneFareMapping: path(ROOTS.app, '/manage/manageCorporate/zoneFareMapping/:id'),
    zoneFareMappingWithModel: path(ROOTS.app, '/manage/manageCorporate/zoneFareMappingWithModel/:id'),
    companiesEdit: path(ROOTS.app, '/manage/manageCompany/editCompany/:id'),
    companiesInfo: path(ROOTS.app, '/manage/manageCompany/companyInfo'),
    // Manage Promocode
    managePromocode: path(ROOTS.app, '/manage/manageCompany/managePromocode'),
    addPromocode: path(ROOTS.app, '/manage/manageCompany/managePromocode/addPromocode'),
    editPromocode: path(ROOTS.app, '/manage/manageCompany/managePromocode/editPromocode/:id'),
    
    // Custom Fare
    manageCustomZone: path(ROOTS.app, '/manage/manageCustomZone'),
    addCustomzone: path(ROOTS.app, '/manage/manageCustomZone/addCustomzone/:id'),
    customzoneFare: path(ROOTS.app, '/manage/manageCustomZone/customzoneFare/:id'),
    customzoneNotification: path(ROOTS.app, '/manage/manageCustomZone/customzoneNotification/:id'),
    customDriverListAddEdit: path(ROOTS.app, '/manage/manageCustomZone/driverListAddEdit/:id'),

    corporates: path(ROOTS.app, '/manage/manageCorporate'),
    zoneGroup: path(ROOTS.app, '/manage/manageCorporate/zoneGroup/:id/:companyId'),
    manageZone: path(ROOTS.app, '/manage/manageCorporate/manageZone'),
    addManageZone: path(ROOTS.app, '/manage/manageCorporate/addManageZone/:id'),
    zoneGroupAdd: path(ROOTS.app, '/manage/manageCorporate/zoneGroupAdd/:id/:companyId'),
    zoneGroupEdit: path(ROOTS.app, '/manage/manageCorporate/zoneGroupEdit/:id/:companyId'),
    // Santhosh Start
    manageManufacturer: path(ROOTS.app, '/manage/manageManufacturer'),
    manageManufacturerAdd:path(ROOTS.app,'/manage/manageManufacturer/manufacturerAdd/:id'),
    manageCarMake:path(ROOTS.app,'/manage/manageCarMake'),
    manageCarMakeAdd:path(ROOTS.app,'/manage/manageCarMake/carMakeAdd/:id'),
    // Santhosh End
    corporatePackage: path(ROOTS.app, '/manage/corporatePackage'),
    addPackage: path(ROOTS.app, '/manage/corporatePackage/addPackage'),
    editPackage: path(ROOTS.app, '/manage/corporatePackage/editPackage/:id'),

    //manag eoffer zone

    manageOfferZone: path(ROOTS.app, '/manage/manageOfferZone'),
    addOfferZone: path(ROOTS.app, '/manage/manageOfferZone/addOfferZone/:id'),

    //manage model- priyanka added
    manageModel: path(ROOTS.app, '/manage/manageModel'),
    addModel: path(ROOTS.app, '/manage/manageModel/addModel/:id'),
    
    
    // Manage Fare
    manageFare: path(ROOTS.app, '/manage/manageFare'),
    addEditFare: path(ROOTS.app, '/manage/manageFare/addEditFare/:id'),
    modelInfo: path(ROOTS.app, '/manage/manageFare/modelInfo/:id'),
    // addFare: path(ROOTS.app, '/manage/manageFare/addFare'),
    // editFare: path(ROOTS.app, '/manage/manageFare/editFare/:id'),



    //manageDivision
    manageDivision : path(ROOTS.app, '/manage/manageDivision'),
    addDivision: path(ROOTS.app, '/manage/manageDivision/addDivision/:id'),


    manageDepartment: path(ROOTS.app, '/manage/manageDepartment'),
    addDepartment: path(ROOTS.app, '/manage/manageDepartment/addDepartment/:id'),


    // Manage Passenger
    passengers: path(ROOTS.app, '/manage/passengers'),
    passengerInfo: path(ROOTS.app, '/manage/passengers/passengerInfo/:id'),
    addEditPassenger: path(ROOTS.app, '/manage/passengers/addEditPassenger/:id'),
    passengerPackage: path(ROOTS.app, '/manage/passengers/passengerPackage'),
    addEditPackage: path(ROOTS.app, '/manage/passengers/addEditPackage/:id'),

    // Location
    companyLocations: path(ROOTS.app, '/manage/manageLocation'),
    companyLocationsAdd: path(ROOTS.app, '/manage/manageLocation/addLocation/:id'),
    companyLocationsEdit: path(ROOTS.app, '/manage/manageLocation/editLocation/:id'),

    // Lifestyle Location
    lifestyleLocations: path(ROOTS.app, '/manage/lifestyleLocation'),
    lifestyleLocationsAdd: path(ROOTS.app, '/manage/lifestyleLocation/addLocation/:id'),
    lifestyleLocationsEdit: path(ROOTS.app, '/manage/lifestyleLocation/editLocation/:id'),

    driverListAddEdit: path(ROOTS.app, '/manage/manageLocation/driverListAddEdit/:id'),
    multipleDropOff: path(ROOTS.app, '/manage/manageLocation/multipleDropOff/:id'),
    LocationCustomizedFare: path(ROOTS.app, '/manage/manageLocation/LocationCustomizedFare/:id'),
    manageLocationQueue: path(ROOTS.app, '/manage/manageLocation/manageLocationQueue'),
    driverLocationsEdit: path(ROOTS.app, '/manage/manageLocation/editDriverLocation/:id'),
    driverLocationsMultiple: path(ROOTS.app, '/manage/manageLocation/multipleDropOffLocation/:id'),
    locationQueue :path(ROOTS.app,'/manage/manageLocation/locationQueue/:id'),
    locationQueueLogs:path(ROOTS.app,'/manageLocationQueueLogs'),
    
    // Tolls
    manageTolls: path(ROOTS.app, '/manage/manageTolls'),
    addEditTolls: path(ROOTS.app, '/manage/manageTolls/addEditTolls/:id'),
    
    // Fine
    manageFine: path(ROOTS.app, '/manage/manageCorporate/manageFine'),
    addFine: path(ROOTS.app, '/manage/manageCorporate/addFine/:id'),
    manageFineCategory: path(ROOTS.app, '/manage/manageCorporate/manageFineCategory'),
    

     // Site Settings
     manageSiteSettings: path(ROOTS.app, '/manage/manageSiteSettings'),
     manageReferralSettings:path(ROOTS.app, '/manage/manageReferralSettings'),
     manageGoogleSettings: path(ROOTS.app, '/manage/manageGoogleSettings'),
     
    // Manage SMS Template
    manageSmsTemplates: path(ROOTS.app, '/manage/manageSmsTemplates'),
    addEditSmsTemplate: path(ROOTS.app, '/manage/manageSmsTemplates/addEditSmsTemplate/:id'),
    

    // SMTP Admin
    // manageAdminSmtpMailSetting: path(ROOTS.app, '/manage/adminMailSetting'),
    editAdminSMTPSetting: path(ROOTS.app, '/manage/mailSetting/editAdminMailSetting'),
    // SMTP company
    manageSmtpMailSetting: path(ROOTS.app, '/manage/mailSetting'),
    addEditMailSetting: path(ROOTS.app, '/manage/mailSetting/addEditMailSetting/:id'),
    
    // Manage SMS Setting 
    // Admin
    editSMSSMTPSetting: path(ROOTS.app, '/manage/smsSetting/editAdminSMSSetting'),

    // Company
    manageSMSSetting: path(ROOTS.app, '/manage/smsSetting'),
    addEditSMSSetting: path(ROOTS.app, '/manage/smsSetting/addEditSmsSetting/:id'),
    // country state city
    manageCountry: path(ROOTS.app, '/manage/manageCountry'),
    addEditManageCountry: path(ROOTS.app, '/manage/manageCountry/addEditCountry/:id'),
    manageState: path(ROOTS.app, '/manage/manageState'),
    addEditManageState: path(ROOTS.app, '/manage/manageState/addEditState/:id'),
    manageCity: path(ROOTS.app, '/manage/manageCity'),
    addEditManageCity: path(ROOTS.app, '/manage/manageCity/addEditCity/:id'),
    // Social Network
    addEditSocialNetwork: path(ROOTS.app, '/manage/manageSocialNetwork/addEditSocialNetwork'),
    
    manageDriver: path(ROOTS.app, '/manage/manageDriver'),
    manageDriverAdd: path(ROOTS.app, '/manage/manageDriver/Add'),
    manageDriverCompanyAdd: path(ROOTS.app, '/manage/manageDriver/Add/:id'),
    manageDriverEdit: path(ROOTS.app, '/manage/manageDriver/Edit/:id'),
    manageDriverInfo: path(ROOTS.app, '/manage/manageDriver/driverInfo/:id'),
    manageSubscriptionInfo: path(ROOTS.app, '/manage/TaxiSubscription/taxiInfo/:id'),
    manageNewDrivers: path(ROOTS.app, '/manage/manageDriver/manageNewDrivers'),
    lostFoundList: path(ROOTS.app, '/manage/manageDriver/lostFoundRequest'),
    exportDriverList: path(ROOTS.app, '/manage/manageDriver/exportDriverList'),
    taxiList: path(ROOTS.app, '/manage/TaxiSubscriptionList'),
    taxiCompletedList: path(ROOTS.app, '/manage/TaxiCompletedList'),
    manageCars: path(ROOTS.app, '/manage'),
    editCars: path(ROOTS.app, '/manage/manageCars/editCars/:id'),
    AddCars: path(ROOTS.app, '/manage/manageCars/addCars'),
    manageCompanyAddCars: path(ROOTS.app, '/manage/manageCars/addCars/:id'),
    carInfo: path(ROOTS.app, '/manage/manageCars/carInfo'),
    assignCars: path(ROOTS.app, '/manage/assignedCars'),
    assignCar: path(ROOTS.app, '/manage/assignCar'),
    manageAssignedCars: path(ROOTS.app, '/manage/manageCars/manageAssignedCars'),
    addAssignCars: path(ROOTS.app, '/manage/assignedCars/add'),
    manageLocations: path(ROOTS.app, '/manageLocations'),
    editLocations: path(ROOTS.app, '/manage/manageLocations/editLocations/:id'),
    AddLocations: path(ROOTS.app, '/manage/manageLocations/addLocations'),
    LocationQueueDriverLogs: path(ROOTS.app, '/manage/locationQueueDriverLogs'),
    LocationEntryExitLogs: path(ROOTS.app, '/manage/locationQueueDriverLogs/locationEntryExitLogs/:kioskId/:driverId'),
    editAssignedCars: path(ROOTS.app, '/manage/assignedCars/editAssignedCars/:id'),
    registeredDriverInfo: path(
      ROOTS.app,
      '/manage/manageDriver/registeredDriverInfo'
    ),
    timeZoneNotification: path(ROOTS.app, '/timeZone/:id'),
    newBooking: path(ROOTS.app, '/newBooking'),
    driverDocument: path(ROOTS.app, '/driver/driverDocuments'),
    settleDetail: path(ROOTS.app, '/engine-report/settle-detail/:id'),
    report: path(ROOTS.app, '/engine-report'),
    driver: path(ROOTS.app, '/driver'),
    ramgps: path(ROOTS.app, '/engine-ramgps'),
    secondReport: path(ROOTS.app, '/secondReport'),
    travelSheet: path(ROOTS.app, '/travel-report'),
    shift: path(ROOTS.app, '/dashboardOld'),
    engine: path(ROOTS.app, '/dashboard'),
    report_new: path(ROOTS.app, '/reportNew'),
    // engine: path(ROOTS.app, '/newBooking'),
    // Taxi Dispatcher: 


    //rejection report
    driverRejectionReport:path(ROOTS.app, '/driverRejectionReport'),
   

    taxiDispatch: path(ROOTS.app, '/taxiDispatch'),
    routes: path(ROOTS.app, '/engine-routes'),
    fuel: path(ROOTS.app, '/engine-fuel'),
    shiftDashboard:path(ROOTS.app ,'/shift'),
    history: path(ROOTS.app, '/vehicle-history'),
    travel: path(ROOTS.app, '/engine-travel'),
    ignition: path(ROOTS.app, '/engine-ignition'),
    model: path(ROOTS.app, '/engine-report/modelList'),
    editModel: path(ROOTS.app, '/engine-report/editModel/:id'),
    allTransactions: path(ROOTS.app, '/report/allTransaction'),
    accountReports: path(ROOTS.app, '/report/accountReports'),
    profileLink: path(ROOTS.app, '/report/transactionReports/:id'),
    completedLink: path(ROOTS.app, '/report/completedInfo'),
    cancelledLinkPage: path(ROOTS.app, '/report/cancelledInfo'),
    CompletedTransactions: path(ROOTS.app, '/report/completedTransaction'),
    cancelledTransactions: path(ROOTS.app, '/report/cancelledTransaction'),
    rejectedTrips: path(ROOTS.app, '/report/rejectedTrips'),
    pendingPaymentDetails: path(ROOTS.app, '/report/pendingPaymentDetails'),
    cancelTripReport: path(ROOTS.app, '/report/cancelTripReport'),
    driverShiftHistory: path(ROOTS.app, '/report/driverShiftHistory'),
    supervisorTripReports: path(ROOTS.app, '/report/supervisorTripReports'),
    supervisorLogs: path(ROOTS.app, '/report/supervisorLogs'),
    historicalData: path(ROOTS.app, '/report/historicalData'),
    activityLogs: path(ROOTS.app, '/report/activityLogs'),
    locationEditLogs: path(ROOTS.app, '/report/locationEditLogs/:logTypeId/:chosenId'),
    transactionlist: path(ROOTS.app, '/report/transactionlist'),
    zoneEntryLogs: path(ROOTS.app, '/report/zoneEntryLogs'),
    companyList: path(ROOTS.app, '/userRoles'),
    managePermission: path(ROOTS.app, '/userRoles/managePermission/:id'),
    editCompany: path(ROOTS.app, '/userRoles/editCompany/:id?'),
    addCompany: path(ROOTS.app, '/userRoles/addCompany'),
    // Handover
    manageHandover: path(ROOTS.app, '/manage/manageHandover'),
    addEditHandover: path(ROOTS.app, '/manage/manageHandover/addEditHandover/:id'),
  },
  general: {
    root: path(ROOTS.app, '/app'),
    app: path(ROOTS.app, '/app'),
    analytics: path(ROOTS.app, '/analytics')
  },
  app: {
    mail: {
      root: path(ROOTS.app, '/mail'),
      all: path(ROOTS.app, '/mail/all'),
      labels: [
        path(ROOTS.app, '/mail/label/:customLabel/:mailId?'),
        path(ROOTS.app, '/mail/:systemLabel/:mailId?')
      ]
    },
    chat: {
      root: path(ROOTS.app, '/chat'),
      new: path(ROOTS.app, '/chat/new'),
      conversation: [
        path(ROOTS.app, '/chat/new'),
        path(ROOTS.app, '/chat/:conversationKey')
      ]
    },
    calendar: path(ROOTS.app, '/calendar')
  },
  management: {
    root: path(ROOTS.app, '/management'),
    user: {
      root: path(ROOTS.app, '/management/user'),
      profile: path(ROOTS.app, '/management/user/profile'),
      cards: path(ROOTS.app, '/management/user/card'),
      list: path(ROOTS.app, '/management/user/list'),
      account: path(ROOTS.app, '/management/user/account')
    },
    eCommerce: {
      root: path(ROOTS.app, '/management/e-commerce'),
      products: path(ROOTS.app, '/management/e-commerce/products'),
      product: path(ROOTS.app, '/management/e-commerce/product/:name'),
      productById: path(
        ROOTS.app,
        '/management/e-commerce/product/nike-air-force-1-ndestrukt'
      ),
      list: path(ROOTS.app, '/management/e-commerce/list'),
      checkout: path(ROOTS.app, '/management/e-commerce/checkout'),
      invoice: path(ROOTS.app, '/management/e-commerce/invoice')
    },
    blog: {
      root: path(ROOTS.app, '/management/blog'),
      post: path(ROOTS.app, '/management/blog/post/:title'),
      postById: path(
        ROOTS.app,
        '/management/blog/post/portfolio-review-is-this-portfolio-too-creative'
      ),
      newPost: path(ROOTS.app, '/management/blog/new-post')
    }
  },
  foundations: {
    root: path(ROOTS.app, '/foundations'),
    colors: path(ROOTS.app, '/foundations/colors'),
    typography: path(ROOTS.app, '/foundations/typography'),
    shadows: path(ROOTS.app, '/foundations/shadows'),
    grid: path(ROOTS.app, '/foundations/grid'),
    icons: path(ROOTS.app, '/foundations/icons')
  },
  components: {
    root: path(ROOTS.app, '/components'),
    accordion: path(ROOTS.app, '/components/accordion'),
    alert: path(ROOTS.app, '/components/alert'),
    autocomplete: path(ROOTS.app, '/components/autocomplete'),
    avatar: path(ROOTS.app, '/components/avatars'),
    badge: path(ROOTS.app, '/components/badges'),
    breadcrumbs: path(ROOTS.app, '/components/breadcrumbs'),
    buttons: path(ROOTS.app, '/components/buttons'),
    chip: path(ROOTS.app, '/components/chips'),
    dialog: path(ROOTS.app, '/components/dialogs'),
    textfield: path(ROOTS.app, '/components/text-fields'),
    label: path(ROOTS.app, '/components/labels'),
    lists: path(ROOTS.app, '/components/lists'),
    menu: path(ROOTS.app, '/components/menu'),
    pagination: path(ROOTS.app, '/components/pagination'),
    pickers: path(ROOTS.app, '/components/pickers'),
    popover: path(ROOTS.app, '/components/popover'),
    progress: path(ROOTS.app, '/components/progress'),
    rating: path(ROOTS.app, '/components/rating'),
    selectionControls: path(ROOTS.app, '/components/selection-controls'),
    snackbar: path(ROOTS.app, '/components/snackbars'),
    slider: path(ROOTS.app, '/components/slider'),
    stepper: path(ROOTS.app, '/components/steppers'),
    tabs: path(ROOTS.app, '/components/tabs'),
    table: path(ROOTS.app, '/components/table'),
    timeline: path(ROOTS.app, '/components/timeline'),
    tooltip: path(ROOTS.app, '/components/tooltips'),
    transferList: path(ROOTS.app, '/components/transfer-list'),
    treeView: path(ROOTS.app, '/components/tree-view'),

    // Extra
    chart: {
      root: path(ROOTS.app, '/extra-components/chart'),
      apexcharts: path(ROOTS.app, '/extra-components/chart/apexcharts'),
      recharts: path(ROOTS.app, '/extra-components/chart/recharts')
    },
    map: {
      root: path(ROOTS.app, '/extra-components/map'),
      google: path(ROOTS.app, '/extra-components/map/googlemap'),
      mapbox: path(ROOTS.app, '/extra-components/map/mapbox')
    },
    editor: path(ROOTS.app, '/extra-components/editor'),
    copyToClipboard: path(ROOTS.app, '/extra-components/copy-to-clipboard'),
    upload: path(ROOTS.app, '/extra-components/upload'),
    carousel: path(ROOTS.app, '/extra-components/carousel'),
    multiLanguage: path(ROOTS.app, '/extra-components/multi-language')
  }
};

export const PATH_DOCS = {
  root: ROOTS.docs,
  introduction: path(ROOTS.docs, '/introduction'),
  started: path(ROOTS.docs, '/getting-started'),
  // Theme UI
  color: path(ROOTS.docs, '/color'),
  typography: path(ROOTS.docs, '/typography'),
  icon: path(ROOTS.docs, '/icon'),
  shadows: path(ROOTS.docs, '/shadows'),
  components: path(ROOTS.docs, '/components'),
  tips: path(ROOTS.docs, '/tips'),

  // Development
  routing: path(ROOTS.docs, '/routing'),
  environmentVariables: path(ROOTS.docs, '/environment-variables'),
  stateManagement: path(ROOTS.docs, '/state-management'),
  apiCalls: path(ROOTS.docs, '/api-calls'),
  analytics: path(ROOTS.docs, '/analytics'),
  authentication: path(ROOTS.docs, '/authentication'),
  multiLanguage: path(ROOTS.docs, '/multi-language'),
  lazyload: path(ROOTS.docs, '/lazyload-image'),
  formHelper: path(ROOTS.docs, '/form-helper'),

  // Changelog
  support: path(ROOTS.docs, '/support'),
  changelog: path(ROOTS.docs, '/changelog')
};
