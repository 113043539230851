/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import { alpha } from '@material-ui/core/styles';

export const getPaletteConfig = () => ({
  PRIMARY: {
    lighter: '#C8FACD', //used
    light: '#5BE584', // used
    main: '#00AB55', // Primary Main used
    dark: '#007B55', // Primary Secondary used
    darker: '#005249' // used
  },

  SECONDARY: {
    lighter: '#D6E4FF',
    light: '#84A9FF',
    main: '#3366FF',
    dark: '#1939B7',
    darker: '#091A7A'
  },
  INFO: {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#6CC8BF',
    dark: '#0C53B7',
    darker: '#04297A'
  },
  SUCCESS: {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D'
  },
  WARNING: {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01'
  },
  ERROR: {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E'
  },
  GREY: {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8)
  }
});

export const loginImage = '/static/illustrations/illustration_login.svg';

export const loginTitle = 'Hi, Welcome Back';

export const logo = '/static/brand/new_logo.png';
