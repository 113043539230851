/* eslint-disable prettier/prettier */

import React, { useEffect, useState } from 'react';
import { MIconButton } from 'src/theme';
import useSound from './useSound'; // adjust the path accordingly
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ringtone from '../NotificationSound/week7-brrring.mp3';
import PropTypes from 'prop-types';

import speakerIcongif from './speakerIcon.gif';
import speakerIcon from './soundIcon.svg';

import './style.css';

Notifications.propTypes = {
  playSound: PropTypes.bool.isRequired,
  setPlaySound: PropTypes.bool.isRequired
};

function Notifications({ playSound, setPlaySound }) {
  
  // console.log("playSound",playSound);

  const [isMuted, setIsMuted] = useState(false);
  const { play, stop } = useSound(ringtone);

  const handleToggleSound = () => {
   
    // console.log("isMuted 2",isMuted);
    if (isMuted) {
      setIsMuted(false);
      play();
    } else {
      setIsMuted(true);
      stop();
    }
  };

  useEffect(() => {
    let timeoutId;
    if (playSound) {
      // console.log("isMuted 1",isMuted);
      if (isMuted) {
        stop();
      } else {
        play();
      }
      // Set playSound back to false after playing the sound
      // setPlaySound(false);
      timeoutId = setTimeout(() => {
        setPlaySound(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [playSound]);

  return (
    <>
      <MIconButton>
        {isMuted ? (
          <VolumeOffIcon
            onClick={handleToggleSound}
            sx={{ cursor: 'pointer' }}
          />
        ) : (
          <>
            {playSound ? (
              <img src={speakerIcongif} alt="icon" height={20} width={20} />
            ) : (
              <img
                onClick={handleToggleSound}
                src={speakerIcon}
                alt="icon"
                height={24}
                width={24}
                color="#637381"
              />
            )}
          </>
        )}
      </MIconButton>
    </>
  );
}

export default Notifications;

// <VolumeUpIcon
//   onClick={handleToggleSound}
//   style={{
//     cursor: 'pointer',
//   }}
//   className="volume-animation"
//   // sx={{ cursor: 'pointer',  className: classes.blink, }}
// />
