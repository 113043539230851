/* eslint-disable prettier/prettier */
import { Icon } from '@iconify/react';
import Scrollbars from 'src/components/Scrollbars';
import SimpleBarReact from 'simplebar-react';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import doneAllFill from '@iconify-icons/eva/done-all-fill';
import bellFill from '@iconify-icons/eva/bell-fill';
import { MBadge } from 'src/theme';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify-icons/eva/close-fill';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { RSLUrl } from 'src/config';
import {
  markAllAsRead,
  getNotifications,
  dashboardNotification,
  notificationBookingId,
  notificationUnread
} from 'src/redux/slices/notifications';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
  ListSubheader,
  SwipeableDrawer,
  Tab,
  ListItem,
  ListItemText,
  Chip
} from '@material-ui/core';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { MIconButton } from 'src/theme';
import Label from '../../../../components/label';
import PropTypes from 'prop-types';
import '../NotificationSound/style.css';
import { Stack } from '@mui/material';
import { getCompanyId } from 'src/redux/slices/authJwt';

const { webAPI_URL } = RSLUrl;

const useStyles = makeStyles((theme) => ({
  root: {},
  listSubheader: {
    ...theme.typography.overline,
    lineHeight: 'unset',
    textTransform: 'uppercase',
    padding: theme.spacing(1, 2.5)
  },
  isUnRead: {
    backgroundColor: theme.palette.action.selected
  }
}));

Notifications.propTypes = {
  doJoin: PropTypes.string.isRequired,
  playSound: PropTypes.bool.isRequired,
  setPlaySoundDojoin: PropTypes.func.isRequired,
  count:PropTypes.number.isRequired
};

const childTab = ['Yet to Assign', 'Follow Up'];

const statusLabels = {
  0: 'Yet to assign',
  9: 'Driver assigned',
  12: 'Driver on the way',
  3: 'Driver arrived',
  2: 'Trip in progress',
  5: 'Waiting for payment',
  1: 'Trip completed',
  4: 'Passenger cancelled',
  6: 'Passenger cancelled',
  8: 'Dispatcher cancelled',
  13: 'Driver cancelled'
};

const statusLabelsEdited = {
  1: 'Edited'
};

function Notifications({ doJoin, playSound, setPlaySoundDojoin ,count}) {
  //  console.log('doJoin', doJoin);

  // console.log('count', count);

 
  const spanRef = useRef(null);

  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const companyIdFromLocalStorage = Number(getCompanyId());

  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();


  const [loader ,setLoader] =useState(false);


  const [filter, setFilter] = useState({
    companyId: 0,
    page: 0,
    limit: 50,
    categoryType: 1,
    statusType: 1
  });

  // Destructuring the relevant data from the Redux store
  const { dashboardNotificationData } = useSelector(
    (state) => state.notifications
  );

  const [notificationData, setNotificationData] = useState(null);
  const [selectedTab, setSelectedTab] = useState('now');
  const [selectedTabChild, setSelectedTabChild] = useState('Yet to Assign');
  const [totalReadStatusOne, setTotalReadStatusOne] = useState(0);

  const tabMapping = {
    now: 1,
    today: 2,
    late: 3,
    future: 4
  };

  const childTabMapping = {
    'Yet to Assign': 1,
    'Follow Up': 2
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  // useEffect(() => {
  //   // console.log('doJoin:', doJoin);

    
  //     const fetchData = async () => {
  //       try {
  //         const apiUrl = `${webAPI_URL}/newBooking/${doJoin}`;

  //         const response = await fetch(apiUrl, {
  //           method: 'POST', // Use POST method or another HTTP method based on your API
  //           headers: {
  //             'Content-Type': 'application/json'
  //             // Add authentication headers here if needed
  //           },
  //           body: JSON.stringify(filter) // Pass the data in the body
  //         });

  //         const result = await response.json();

  //         setNotificationData(result.responseData);
  //       } catch (error) {
  //         console.error('API call error:', error);
  //       }
  //     };

  //     if (doJoin !== '' && doJoin !== undefined && doJoin !== null ) {
  //     fetchData();
  //   }
  // }, [doJoin]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log("doJoin", doJoin);
  
       
        if (!doJoin) {
          // console.log("Invalid doJoin, skipping API call");
          return;
        }
  
        const apiUrl = `${webAPI_URL}/newBooking/${doJoin}`;
  
        const response = await fetch(apiUrl, {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
          
          },
          body: JSON.stringify(filter), 
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json();
        setNotificationData(result.responseData);
      } catch (error) {
        // console.error('API call error:', error.message || error);
      }
    };
  
  
    if (doJoin) {
      fetchData();
      // console.log('testing doJoin 1', doJoin);
    } 
  
  }, [doJoin, webAPI_URL]); 
  
  // console.log('testing doJoin', doJoin);

  useEffect(() => { 

      setTotalReadStatusOne(count);

  }, [notificationData]);

  // Add apiUrl as a dependency if it can change

  const handleChangeTab = (event, newValue) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      categoryType: tabMapping[newValue],
      page:0
    }));

    setNotificationData(null);
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (filter.categoryType !== '' && filter.statusType !== '') {
        try {
          const apiUrl = `${webAPI_URL}/newBooking/${doJoin}`;


          const response = await fetch(apiUrl, {
            method: 'POST', // Use POST method or another HTTP method based on your API
            headers: {
              'Content-Type': 'application/json'
              // Add authentication headers here if needed
            },
            body: JSON.stringify(filter) // Pass the data in the body
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const result = await response.json();
          setNotificationData(result.responseData);
        } catch (error) {
          // console.error('Error fetching data:', error);
         
        }
      }
    };

    if(doJoin){
      fetchData();
    }
  }, [filter.categoryType, filter.statusType]);

  useEffect(() => {

  
      
    const fetchData = async () => {
      if (filter.categoryType !== '' && filter.statusType !== '') {
        try {
          const apiUrl = `${webAPI_URL}/newBooking/${doJoin}`;

          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(filter) 
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const result = await response.json();
  
          // setLoader(false);
        
           setNotificationData((prevData) => {
            if (prevData) {
              return {
                ...prevData,
                b2cDoJoinList: [...prevData.b2cDoJoinList, ...result.responseData.b2cDoJoinList]
              };
            }
            return result.responseData;
          });
        
        } catch (error) {
          // console.error('Error fetching data:', error);
        }
      }
    };
  
    if(doJoin && filter.page !== 0){
      fetchData();
    }
   
  }, [filter.page]);
  

  const handleChangeChildTab = (event, newValue) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      statusType: childTabMapping[newValue]
    }));

    setNotificationData(null);
    setSelectedTabChild(newValue);
  };

  const handleMarkAllAsRead = (id) => {
    let tripIds = id.map((item) => item.tripId);
    const obj = {
      tripIds: tripIds,
      readStatus: '1'
    };
    dispatch(notificationUnread(obj));
  };

  const handleClickRead = (id) => {
    // console.log('handleClickRead:', id.tripId);
    const obj = {
      tripIds: [id.tripId],
      readStatus: '1'
    };
    dispatch(notificationUnread(obj));
    dispatch(dashboardNotification({ companyId: companyIdFromLocalStorage }));
  };

  const handleViewBooking = (id, pickupTime, type) => {
    let tripId = { id: id };
    dispatch(notificationBookingId(tripId));
    dispatch(dashboardNotification({ companyId: companyIdFromLocalStorage }));
    sessionStorage.setItem('notifyCurrentTab', JSON.stringify(type));
    sessionStorage.setItem('tripId', JSON.stringify(tripId.id));
    sessionStorage.setItem('date', JSON.stringify(pickupTime));
    sessionStorage.setItem('toDate', JSON.stringify(pickupTime));
    sessionStorage.setItem('viewBooking', JSON.stringify(1));
    history.push('/app/newBooking');
    setOpen(false);
  };

  

  const getIconColor = (type) => {
    if (type === 'now') return 'primary';
    if (type === 'today') return 'info';
    if (type === 'future') return 'warning';
    if (type === 'late') return 'error';
    return 'primary';
  };

  useEffect(() => {
    if (playSound) {
      const timeoutId = setTimeout(() => {
        setPlaySoundDojoin(false);
      }, 2000);

      // Cleanup the timeout to avoid potential memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [playSound, setPlaySoundDojoin]);

  const handleOpenCopySnackbar = () => {
    enqueueSnackbar('Copy success!', {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  };

  const handleCloseCopySnackbar = () => {
    enqueueSnackbar('Copy failed!', {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  };

  const handleCopyTripId = (tripId) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(tripId).then(
        () => {
          handleOpenCopySnackbar();
        },
        () => {
          handleCloseCopySnackbar();
        }
      );
    } else {
      // Fallback method
      const textArea = document.createElement('textarea');
      textArea.value = tripId;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          handleOpenCopySnackbar();
        } else {
          handleCloseCopySnackbar();
        }
      } catch (err) {
        handleCloseCopySnackbar();
      }

      document.body.removeChild(textArea);
    }
  };

 


  const scrollRef= useRef(null);

  useEffect(() => {
    const contentWrapper = scrollRef.current?.contentWrapperEl;

    if (contentWrapper) {
      // console.log('Initial scrollHeight:', contentWrapper.scrollHeight);

      const handleScroll = () => {
        // console.log('Scroll event detected');

        // setLoader(true);

        const { scrollTop, scrollHeight, clientHeight } = contentWrapper;
        // console.log(scrollTop);
        // console.log(clientHeight);
        // console.log(scrollHeight);
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          // console.log('Reached bottom, updating filter');
          setFilter((prevFilter) => ({
            ...prevFilter,
            page: prevFilter.page + 1
          }));

        }
      };

      contentWrapper.addEventListener('scroll', handleScroll);

      return () => {
        contentWrapper.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollRef.current?.contentWrapperEl?.scrollHeight]);

  // console.log("NData:",notificationData?.b2b)
  // console.log("NData:",notificationData?.b2b[selectedTab]?.ids?.filter((id) => id.travelStatus === 0))
  // console.log('notificationDatas:1', notificationData);
  return (
    <>
      <Tooltip title="DoJoin">
        <MIconButton
          ref={anchorRef}
          onClick={() => setOpen(true)}
          color={isOpen ? 'primary' : 'default'}
        >
          <Typography variant="span" sx={{ fontSize: '9px' }}>
            DoJoin
          </Typography>
          <Badge badgeContent={totalReadStatusOne} color="error">
            <Icon
              className={`${playSound ? 'iconAnimation' : ''}`}
              icon={bellFill}
              width={20}
              height={20}
            />
          </Badge>
        </MIconButton>
      </Tooltip>

      <SwipeableDrawer
        width={500}
        open={isOpen}
        anchor="right"
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        PaperProps={{
          style: { width: 500 }
        }}
      >
        <Box sx={{ py: 2 }}>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 }
              }
            }}
          >
            {['now', 'today', 'late', 'future'].map((type) => (
              <Tab
                key={type}
                value={type} // Use the string type for Tab value
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{type}</span>
                    {notificationData?.[
                      `b2c${type.charAt(0).toUpperCase() + type.slice(1)}Count`
                    ] > 0 && (
                      <Label
                        color={getIconColor(type)}
                        style={{ marginLeft: 5, padding: '2px' }}
                      >
                        {
                          notificationData[
                            `b2c${
                              type.charAt(0).toUpperCase() + type.slice(1)
                            }Count`
                          ]
                        }
                      </Label>
                    )}
                  </div>
                }
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {notificationData?.b2cDoJoinList && (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  You have {'  '}
                  {
                    notificationData.b2cDoJoinList.filter((id) => id.readStatus === 0)
                      .length
                  }{' '}
                  unread messages
                </Typography>
              )}
            </Typography>
          </Box>

          {notificationData &&
            notificationData?.b2cDoJoinList.filter((id) => id.readStatus === 0)
              .length > 0 && (
              <Tooltip title=" Mark all as read">
                <MIconButton
                  color="primary"
                  onClick={() =>
                    handleMarkAllAsRead(notificationData?.b2cDoJoinList.tripId)
                  }
                >
                  <Icon icon={doneAllFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
            )}
        </Box>
        <Divider />
        <Box sx={{ py: 2 }}>
          <Tabs
            value={selectedTabChild}
            onChange={handleChangeChildTab}
            centered
            aria-label="child tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 }
              }
            }}
          >
            {childTab.map((key, index) => (
              <Tab
                key={key}
                value={key} // Ensure this matches the tabMapping keys
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{key}</span>
                    <Label
                      color={getIconColor(key)}
                      style={{ marginLeft: 5, padding: '2px' }}
                    >
                     {notificationData &&
                        (key === 'Yet to Assign'
                          ? notificationData.b2cYetToAssignCount
                          : key === 'Follow Up'
                          ? notificationData.b2cFollowUpCount
                          : 0)}
                    </Label>
                  </div>
                }
              />
            ))}
          </Tabs>
        </Box>
        <Box>
          <SimpleBarReact ref={scrollRef} style={{
          overflow: 'auto',
          maxHeight: '700px'
        }}>
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.listSubheader}
                >
                  Bookings (
                  {notificationData &&
                    notificationData[
                      `b2c${
                        Object.keys(tabMapping)
                          .find(
                            (key) => tabMapping[key] === filter.categoryType
                          )
                          .charAt(0)
                          .toUpperCase() +
                        Object.keys(tabMapping)
                          .find(
                            (key) => tabMapping[key] === filter.categoryType
                          )
                          .slice(1)
                      }Count`
                    ]}
                  )
                </ListSubheader>
              }
            >
              {selectedTabChild === 'Yet to Assign' ? (
                notificationData &&
                notificationData?.b2cDoJoinList &&
                notificationData.b2cDoJoinList.length !== 0 ? (
                  notificationData.b2cDoJoinList
                    ?.filter((id) => id.travelStatus === 0)
                    .map((id) => (
                      <ListItem
                        button
                        to="#"
                        disableGutters
                        key={id.tripId}
                        component={RouterLink}
                        sx={{
                          px: 2.5,
                          py: 2,
                          backgroundColor: `${
                            id.readStatus !== 1
                              ? theme.palette.action.selected
                              : ''
                          }`,
                          position: 'relative'
                        }}
                      >
                        <ListItemText
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClickRead(id);
                          }}
                          primary={
                            <>
                              <Typography variant="subtitle2">
                                Trip Id: {id.tripId}
                                <CopyToClipboard
                                  text={id.tripId}
                                  sx={{ width: '5%' }}
                                >
                                  <ContentCopyIcon
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      handleCopyTripId(id.tripId);
                                    }}
                                    style={{
                                      fontSize: '15px',
                                      cursor: 'pointer'
                                    }}
                                  />
                                </CopyToClipboard>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{
                                    color: 'text.secondary',
                                    textTransform: 'capitalize'
                                  }}
                                >
                                  &nbsp; &nbsp; &nbsp; {id.message}
                                </Typography>
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  mt: 2
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  sx={{ height: '30px', width: 'auto' }}
                                  onClick={() =>
                                    handleViewBooking(
                                      id.tripId,
                                      id.pickupTime,
                                      id.type
                                    )
                                  }
                                >
                                  View Booking
                                </Button>
                                <Tooltip title="Pickup Time">
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{
                                      color: 'text.secondary',
                                      textTransform: 'capitalize'
                                    }}
                                  >
                                    &nbsp; {id?.pickupTime}
                                  </Typography>
                                </Tooltip>
                                <Chip
                                  size="small"
                                  label={statusLabels[id.travelStatus]}
                                  color="primary"
                                />
                                {id.tripEdited == 1 ? (
                                  <Chip
                                    size="small"
                                    label={statusLabelsEdited[id.tripEdited]}
                                    color="secondary"
                                  />
                                ) : (
                                  ''
                                )}
                              </Box>
                            </>
                          }
                        />
                        <MBadge
                          color="info"
                          variant="dot"
                          sx={{
                            position: 'absolute',
                            right: '20px',
                            top: '20px',
                            display: `${id.readStatus !== 1 ? 'block' : 'none'}`
                          }}
                        ></MBadge>
                      </ListItem>
                    ))
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'text.secondary',
                        textTransform: 'capitalize'
                      }}
                    >
                      No Bookings Yet!
                    </Typography>
                  </Box>
                )
              ) : notificationData &&
                notificationData?.b2cDoJoinList &&
                notificationData.b2cDoJoinList.length !== 0 ? (
                notificationData.b2cDoJoinList
                  ?.filter((id) => id.travelStatus !== 0)
                  .map((id) => (
                    <ListItem
                      button
                      to="#"
                      disableGutters
                      key={id.tripId}
                      component={RouterLink}
                      sx={{
                        px: 2.5,
                        py: 2,
                        backgroundColor: `${
                          id.readStatus !== 1
                            ? theme.palette.action.selected
                            : ''
                        }`,
                        position: 'relative'
                      }}
                    >
                      <ListItemText
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleClickRead(id);
                        }}
                        primary={
                          <>
                            <Typography variant="subtitle2">
                              Trip Ids: {id.tripId}
                              <CopyToClipboard
                                text={id.tripId}
                                sx={{ width: '5%' }}
                              >
                                <ContentCopyIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleCopyTripId(id.tripId);
                                  }}
                                  style={{
                                    fontSize: '15px',
                                    cursor: 'pointer'
                                  }}
                                />
                              </CopyToClipboard>
                              <Typography
                                component="span"
                                variant="body2"
                                sx={{
                                  color: 'text.secondary',
                                  textTransform: 'capitalize'
                                }}
                              >
                                &nbsp; &nbsp; &nbsp; {id.message}
                              </Typography>
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{ height: '30px', width: 'auto' }}
                                onClick={() =>
                                  handleViewBooking(
                                    id.tripId,
                                    id.pickupTime,
                                    id.type
                                  )
                                }
                              >
                                View Booking
                              </Button>
                              <Tooltip title="Pickup Time">
                                <Typography
                                  component="span"
                                  variant="body2"
                                  sx={{
                                    color: 'text.secondary',
                                    textTransform: 'capitalize'
                                  }}
                                >
                                  &nbsp; {id?.pickupTime}
                                </Typography>
                              </Tooltip>
                              <Chip
                                size="small"
                                label={statusLabels[id.travelStatus]}
                                color="primary"
                              />
                              {id.tripEdited == 1 ? (
                                <Chip
                                  size="small"
                                  label={statusLabelsEdited[id.tripEdited]}
                                  color="secondary"
                                />
                              ) : (
                                ''
                              )}
                            </Box>
                          </>
                        }
                      />
                      <MBadge
                        color="info"
                        variant="dot"
                        sx={{
                          position: 'absolute',
                          right: '20px',
                          top: '20px',
                          display: `${id.readStatus !== 1 ? 'block' : 'none'}`
                        }}
                      ></MBadge>
                    </ListItem>
                  ))
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'text.secondary',
                      textTransform: 'capitalize'
                    }}
                  >
                    No Bookings Yet!
                  </Typography>
                </Box>
              )}
            </List>
            {/* {loader ? (
            
            <CircularProgress
              sx={{ color: 'primary', width: '25px', height: '25px' ,marginLeft:'50%'}}
            />
         
        ): ''
      } */}
          </SimpleBarReact>
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default Notifications;
